import React, { useEffect, useState } from 'react';

const API_KEY = '79eb63ac673aad38e19bc7faf220de97';

const SelectedBibleText6 = ({ selectedData6 }) => {
  const [verseContent, setVerseContent] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchVerseContent = async () => {
      const { versionId, verse } = selectedData6;

      if (!versionId || !verse) {
        setError('Please select a version, book, chapter, and verse.');
        return;
      }

      const url = `https://api.scripture.api.bible/v1/bibles/${versionId}/verses/${verse}?content-type=text`;

      try {
        const response = await fetch(url, {
          headers: {
            'api-key': API_KEY,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching verse content');
        }

        const data = await response.json();
        setVerseContent(data.data.content);
        setError('');
      } catch (error) {
        setError(error.message);
      }
    };

    fetchVerseContent();
  }, [selectedData6]);

  return (
    <div className='text-dark'>
      {error ? <p>{error}</p> : <p>{verseContent}</p>}
    </div>
  );
};

export default SelectedBibleText6;
