import React from 'react';
import Table from 'react-bootstrap/Table';





function Pagina3({info, handleOnchange}) {

    
    
  return (

   

    <div className='col-11 mt-5'>
        <Table className='table table-bordered border border-3 border-dark'>
            <tbody>
                <tr>
                    <td className='bg-secondary'>Duracion</td>
                    <td colSpan={2} className='bg-secondary'>Encuadre</td>
                    
                </tr>
                <tr>
                    <td className='col-1' rowSpan={14}><h2 className='mt-5'>5 min</h2></td>
                    <td className='bg-light' colSpan={2}>
                            <h4>1.-Mediante la presentación digital conforme al documento de planeación de la conferencia: </h4>
                           
                    </td>
                    
                    <td>
                    
                    </td>
                </tr>
                
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>·Presentándose ante la audiencia al mencionar, {info.nombre} {info.apellido}, {info.cargo} de {info.lugar}.</h4></td>
                    
                </tr>
                <tr>
                   <td className='bg-light' colSpan={2}><h4>Soy el/la {info.cargo} {info.nombre} {info.apellido} de la iglesia {info.lugar} en {info.calle} {info.ciudad} {info.estado} Mexico</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>·Refiriendo el tema a abordar durante la conferencia.</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>{info.tema}</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>Al finalizar {info.la} {info.los} {info.verbos} {info.proposito}</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>·Mencionando las Ideas Centrales de forma general.</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' ><h4>ICF1</h4></td>
                    <td className='bg-light' ><h4>{info.icf1}</h4></td>
                </tr>
                <tr>
                    <td className='bg-light' ><h4>ICF2</h4></td>
                    <td className='bg-light' ><h4>{info.icf2}</h4></td>
                </tr>
                <tr>
                    <td className='bg-light' ><h4>ICF3</h4></td>
                    <td className='bg-light' ><h4>{info.icf3}</h4></td>
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>·Comentando los Beneficios de la Conferencia.</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>{info.beneficio}</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>·Y de forma Breve Relaciónelo con su Experiencia Personal.</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}>
                    <input
                            type="text"  
                            name='breve'
                            className='col-12'
                            value={info.breve || ""}
                            onChange={handleOnchange} 
                        />
                    </td>
                </tr>
            </tbody>    
        </Table>
        <Table className='table table-bordered border border-3 border-dark'>
            <tbody>
                <tr>
                    <td className='col-1' rowSpan={14}><h2 className='mt-5'>35 min</h2></td>
                    <td className='bg-light' colSpan={2}>
                            <h4>2.- FACILITA EL PROCESO DE COMPRENSIÓN MEDIANTE LA EXPOSICIÓN DEL DISCURSO AL IMPARTIR LA CONFERENCIA:</h4>
                    </td>
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} >
                        <h4>· Dirigiendo la mirada a toda la audiencia durante el desarrollo de la conferencia, acorde a las condiciones de Interacción,</h4>
                        <h4>· Empleando expresiones faciales/gestos, ademanes, posturas y congruentes con el contenido que se transmite, </h4>
                        <h4>• Manteniendo una postura dinámica dentro del espacio de la conferencia, con el fin de captar/enganchar la atención de la audiencia,</h4>
                        <h4>• Utilizando variantes de tono, modulación, ritmo y volumen de voz durante la impartlción de la conferencia para enfatizar información prioritaria en el </h4>
                        <h4>•	REFORZANDO EL TEMA DE LA CONFERENCIA CON TEXTOS DE ENFOQUE TEOLÓGICO BASE/OTROS ADICIONALES, AL CITAR LA FUENTE DE ORIGEN/AUTOR,</h4>
                    </td> 
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>·Explicando las ideas centrales de manera detallada y lo fundamental con base en las referencias bibliográficas, información de enfoque teológico.</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>{info.icf1}</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} >
                    <textarea
                            type="text"  
                            name='desarrolloicf1'
                            className='col-12'
                            value={info.desarrolloicf1 || ""}
                            onChange={handleOnchange} 
                        />
                    </td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>{info.icf2}</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} >
                    <textarea
                            type="text"  
                            name='desarrolloicf2'
                            className='col-12'
                            value={info.desarrolloicf2 || ""}
                            onChange={handleOnchange} 
                        />
                    </td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>{info.icf3}</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} >
                    <textarea
                            type="text"  
                            name='desarrolloicf3'
                            className='col-12'
                            value={info.desarrolloicf3 || ""}
                            onChange={handleOnchange} 
                        />
                    </td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>Expresando EXPERIENCIAS VIVENCIALES/PERSONALES (TESTIMONIO PERSONAL), con relación al tema desarrollado con el fin de generar y fortalecer empatía con la audiencia, </h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} >
                    <textarea
                            type="text"  
                            name='testimonio'
                            className='col-12'
                            value={info.testimonio || ""}
                            onChange={handleOnchange} 
                        />
                    </td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>Utilizando ejemplos sobre VIVENCIAS/EXPERIENCIAS DE TERCEROS (TESTIMONIO DE OTROS) relacionándolos con el tema tratado con el fin de generar y fortalecer empatía con la audiencia,</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} >
                    <textarea
                            type="text"  
                            name='testimonio2'
                            className='col-12'
                            value={info.testimonio2 || ""}
                            onChange={handleOnchange} 
                        />
                    </td>
                    
                </tr>
                
            </tbody>
            
        </Table>
    </div>
    
  );
}

export default Pagina3;