import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function Menu() {
  return (
    <div className='d-flex '>
        <Navbar className="bg-body-tertiary">
            <Container fluid>
                <div className='d-flex '>
                    <div className="d-flex">
                        <div className='mt-5'>
                            <img
                            src="/img/logo_conoce.png"
                            width="80"
                            height="80"
                            className="align-top"
                            alt="React Bootstrap logo"
                            />
                        </div>
                    
                        <div className='m-5'>
                            <img
                            src="/img/logo_ece.png"
                            width="80"
                            height="80"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                            />
                        </div>
                        <div className='m-5'>
                            <img
                            src="/img/logo_uni.png"
                            width="80"
                            height="80"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                            />
                        </div>
                    </div>
                    
                    <div className='text-dark mt-3 d-flex flex-column align-items-start'>
                        <div>
                        <h2>DIRECCION GENERAL</h2>
                        </div>
                        <div>
                        <h3>ENTIDAD DE CERTIFICACIÓN Y EVALUACION ECE500-21</h3>
                        </div>
                        <div>
                        <h2>INSTITUTO UNIVERSITARIO AZTECA DE CHIAPAS</h2>
                        </div>
                        <div className='pie'>
                            <h4>plkjñlkjñlkjñalksdjfasldkfjasdlkjaflksdjfasñldkfjdsalfdkjañlkdsfhañslk</h4>
                        </div>
                    
                    
                    
                    </div>
                    
                </div>
            </Container>
        </Navbar>  
    </div>
  );
}

export default Menu;