import React,{useState} from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import './App.css';
import Formulario from './Formulario';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './Menu';
import Pagina2 from './Pagina2';
import Pagina3 from './Pagina3';
import Pagina4 from './Pagina4';
import BibleDropdown from './components/BibleDropdown';
import SelectedBibleText from './components/SelectedBibleText';
import BibleDropdown2 from './components/BibleDropdown2';
import SelectedBibleText2 from './components/SelectedBibleText2';
import BibleDropdown3 from './components/BibleDropdown3';
import SelectedBibleText3 from './components/SelectedBibleText3';
import BibleDropdown4 from './components/BibleDropdown4';
import SelectedBibleText4 from './components/SelectedBibleText4';
import BibleDropdown5 from './components/BibleDropdown5';
import SelectedBibleText5 from './components/SelectedBibleText5';
import BibleDropdown6 from './components/BibleDropdown6';
import SelectedBibleText6 from './components/SelectedBibleText6';
import BibleDropdown7 from './components/BibleDropdown7';
import SelectedBibleText7 from './components/SelectedBibleText7';
import BibleDropdown8 from './components/BibleDropdown8';
import SelectedBibleText8 from './components/SelectedBibleText8';
import BibleDropdown9 from './components/BIbleDropdown9';
import SelectedBibleText9 from './components/SelectedBibleText9';
import BibleDropdown10 from './components/BibleDropdown10';
import SelectedBibleText10 from './components/SelectedBibleText10';
import BibleDropdown11 from './components/BibleDropdown11';
import SelectedBibleText11 from './components/SelectedBibleText11';
import BibleDropdown12 from './components/BibleDropdown12';
import SelectedBibleText12 from './components/SelectedBIbleText12';
import ParentComponent from './BibleDitails/ParentComponent';


function App() {

  const [selectedData, setSelectedData] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData2, setSelectedData2] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData3, setSelectedData3] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData4, setSelectedData4] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData5, setSelectedData5] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData6, setSelectedData6] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData7, setSelectedData7] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData8, setSelectedData8] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData9, setSelectedData9] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData10, setSelectedData10] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData11, setSelectedData11] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });

  const [selectedData12, setSelectedData12] = useState({
    version: '',
    book: '',
    chapter: '',
    verse: '',
  });


  const [bibleData, setBibleData] = useState([]);
  const [info, setInfo] = useState({});
  
  

    const handleOnchange = ({target}) =>{
        setInfo({
            ...info,
            [target.name]: target.value
        })
      }      

      
    
  return (
    <Router>
        <div className="App">
            <header className="App-header">
            <Menu/>
            <Formulario info={info} handleOnchange={handleOnchange} bibleData={bibleData} selectedData={selectedData} />
            <Pagina2 info={info} handleOnchange={handleOnchange} bibleData={bibleData} setBibleData={setBibleData} selectedData={selectedData} />
            <Pagina3 info={info} handleOnchange={handleOnchange} bibleData={bibleData} setBibleData={setBibleData}/>
            <Pagina4 info={info} handleOnchange={handleOnchange} bibleData={bibleData}/>
            

            <SelectedBibleText selectedData={selectedData}/>
          </header>
        </div>
    </Router>
    
  );
}

export default App;
