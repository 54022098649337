
import React, {useState} from 'react';
import Table from 'react-bootstrap/Table';
import BibleDropdown from './components/BibleDropdown';
import SelectedBibleText from './components/SelectedBibleText';
import BibleDropdown2 from './components/BibleDropdown2';
import SelectedBibleText2 from './components/SelectedBibleText2';
import BibleDropdown3 from './components/BibleDropdown3';
import SelectedBibleText3 from './components/SelectedBibleText3';
import BibleDropdown4 from './components/BibleDropdown4';
import SelectedBibleText4 from './components/SelectedBibleText4';
import BibleDropdown5 from './components/BibleDropdown5';
import SelectedBibleText5 from './components/SelectedBibleText5';
import BibleDropdown6 from './components/BibleDropdown6';
import SelectedBibleText6 from './components/SelectedBibleText6';
import BibleDropdown7 from './components/BibleDropdown7';
import SelectedBibleText7 from './components/SelectedBibleText7';
import BibleDropdown8 from './components/BibleDropdown8';
import SelectedBibleText8 from './components/SelectedBibleText8';
import BibleDropdown9 from './components/BIbleDropdown9';
import SelectedBibleText9 from './components/SelectedBibleText9';
import BibleDropdown10 from './components/BibleDropdown10';
import SelectedBibleText10 from './components/SelectedBibleText10';
import BibleDropdown11 from './components/BibleDropdown11';
import SelectedBibleText11 from './components/SelectedBibleText11';
import BibleDropdown12 from './components/BibleDropdown12';
import SelectedBibleText12 from './components/SelectedBIbleText12';




function Pagina2({info, handleOnchange, }) {

    
    
    const [selectedData, setSelectedData] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
      });

    const [selectedData2, setSelectedData2] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    }); 
    
    const [selectedData3, setSelectedData3] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    }); 

    const [selectedData4, setSelectedData4] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    }); 

    const [selectedData5, setSelectedData5] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });

    const [selectedData6, setSelectedData6] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });

    const [selectedData7, setSelectedData7] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });

    const [selectedData8, setSelectedData8] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });

    const [selectedData9, setSelectedData9] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });

    const [selectedData10, setSelectedData10] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });

    const [selectedData11, setSelectedData11] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });

    const [selectedData12, setSelectedData12] = useState({
        version: '',
        book: '',
        chapter: '',
        verse: '',
    });
    
  return (
    <div className='col-11 mt-5'>
        
        <Table className='table table-bordered border border-3 border-dark'>
            <tbody>
            <tr>
                    <td colSpan={2}><h3>IDEAS CENTRALES FUNDAMENTADAS APLICANDO LOS PRINCIPIOS DE LA HERMENÉUTICA</h3></td>
                    <td><h4>REFERENCIAS BIBLIOGRÁFICAS / FUENTES DE INFORMACIÓN</h4></td>
                </tr>
                <tr>
                    <td className='col-1' rowSpan={4}><h2>ICF1</h2></td>
                    <td className='bg-light col-8'>
                        <input 
                                type="text"  
                                name='icf1'
                                className='col-12'
                                value={info.icf1 || ""}
                                onChange={handleOnchange} 
                            />
                        <SelectedBibleText selectedData={selectedData} />
                        
                    </td>
                    <td className=' col-3 bg-light' >
                        <h2 className='bg-citas'>Citas:</h2>
                        <BibleDropdown setSelectedData={setSelectedData}/>
                    </td>
                </tr>
                
                <tr>
                    <td className='bg-light'>
                    
                    <SelectedBibleText2 selectedData2={selectedData2} />
                    </td>
                    <td className='bg-light'>
                        <h2 className='bg-citas'> Adicionales:</h2>
                        <BibleDropdown2 setSelectedData2={setSelectedData2}/>
                    </td>
                </tr>
                <tr>
                   <td className='bg-light'>
                   <SelectedBibleText3 selectedData3={selectedData3} />
                   </td>
                    <td className='bg-light'>
                        <BibleDropdown3 setSelectedData3={setSelectedData3}/>   
                    </td>
                </tr>
                <tr>
                    <td className='bg-light'>
                    <SelectedBibleText4 selectedData4={selectedData4} />
                    </td>
                    <td className='bg-light'>
                        <BibleDropdown4 setSelectedData4={setSelectedData4}/>
                    </td>
                </tr>
            </tbody>
        </Table>
        <Table className='table table-bordered border border-3 border-dark'>
            <tbody>
                
                <tr>
                <td className='col-1' rowSpan={4}><h2>ICF2</h2></td>
                    <td className='bg-light col-8'>
                        <input 
                            type="text"  
                            name='icf2'
                            className='col-12'
                            value={info.icf2 || ""}
                            onChange={handleOnchange} 
                        />
                    <SelectedBibleText5 selectedData5={selectedData5} />
                    </td>
                    <td className=' col-3 bg-light' >
                        <h2 className='bg-citas'>Citas:</h2>
                        <BibleDropdown5 setSelectedData5={setSelectedData5}/>
                    </td>
                </tr>
                
                <tr>
                    <td className='bg-light'>
                    <SelectedBibleText6 selectedData6={selectedData6} />
                    </td>
                    <td className='bg-light'>
                        <h2 className='bg-citas'>Adicionales:</h2>
                        <BibleDropdown6 setSelectedData6={setSelectedData6}/>
                    </td>
                </tr>
                <tr>
                   <td className='bg-light'>
                   <SelectedBibleText7 selectedData7={selectedData7} />
                   </td>
                    <td className='bg-light'>
                        <BibleDropdown7 setSelectedData7={setSelectedData7}/>
                    </td>
                </tr>
                <tr>
                    <td className='bg-light'>
                    <SelectedBibleText8 selectedData8={selectedData8} />
                    </td>
                    <td className='bg-light'>
                        <BibleDropdown8 setSelectedData8={setSelectedData8}/>
                    </td>
                </tr>
            </tbody>
        </Table>
        <Table className='table table-bordered border border-3 border-dark'>
            <tbody>
            
                <tr>
                    <td className='col-1' rowSpan={4}><h2>ICF3</h2></td>
                    <td className='bg-light col-8'>
                        <input
                            type="text"  
                            name='icf3'
                            className='col-12'
                            value={info.icf3 || ""}
                            onChange={handleOnchange} 
                        />
                        <SelectedBibleText9 selectedData9={selectedData9} />    
                    </td>
                    <td className=' col-3 bg-light' >
                        <h2 className='bg-citas'>Citas:</h2>
                        <BibleDropdown9 setSelectedData9={setSelectedData9}/>
                        
                    </td>
                </tr>
                
                <tr>
                    <td className='bg-light'>
                    <SelectedBibleText10 selectedData10={selectedData10} /> 
                    </td>
                    <td className='bg-light'>
                        <h2 className='bg-citas'>Adicionales:</h2>
                        <BibleDropdown10 setSelectedData10={setSelectedData10}/>
                    </td>
                </tr>
                <tr>
                   <td className='bg-light'>
                   <SelectedBibleText11 selectedData11={selectedData11} />
                   </td>
                    <td className='bg-light'>
                        <BibleDropdown11 setSelectedData11={setSelectedData11}/>       
                    </td>
                </tr>
                <tr>
                    <td className='bg-light'>
                    <SelectedBibleText12 selectedData12={selectedData12} />
                    </td>
                    <td className='bg-light'>
                        <BibleDropdown12 setSelectedData12={setSelectedData12}/>
                    </td>
                </tr>
            </tbody>
        </Table>
        
       
    </div>
    
  );
}

export default Pagina2;