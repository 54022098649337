
import React, {useState, useEffect, lazy } from 'react';
import Table from 'react-bootstrap/Table';
import BibleDropdown from './components/BibleDropdown';
import SelectedBibleText from './components/SelectedBibleText';
import ParentComponent from './BibleDitails/ParentComponent';
import { Link } from 'react-router-dom';



function Formulario({info, handleOnchange, selectedData}) {

    useEffect(() => {
        const fetchVerseContent = async () => {
          const { versionId, book, chapter, verse } = selectedData;
    
          
    
        };
    
        fetchVerseContent();
      }, [selectedData]);

    


    const options = [
        {label:'Pastor', value:'Pastor'},
        {label:'Pastor General', value:'Pastor General'},
        {label:'Pastor Asociado', value:'Pastor Asociado'},
        {label:'Lider', value:'Lider'},
        {label:'Ministro', value:'Ministro'},
        {label:'Conferencista', value:'Conferencista'},
        {label:'Predicador', value:'Predicador'}
        
    ];

    const options2 =[
        {label: 'la predicación', value: 'la predicacion'},
        {label: 'el sermón', value: 'el sermón'},
        {label: 'la conferencia', value: 'la conferencia'},
        {label: 'el mensaje', value: 'el mensaje'},
        {label: 'la homilía', value: 'la homilía'},       
    ];

    const options3 =[
        {label: 'los asistentes', value: 'los asistentes'},
        {label: 'la iglesia', value: 'la iglesia'},
        {label: 'la congregación', value: 'la congregación'},
        {label: 'las mujeres', value: 'las mujeres'},
        {label: 'los varones', value: 'los varones'},   
        {label: 'los líderes', value: 'los líderes'},
        {label: 'los niños', value: 'los niños'},
        {label: 'las personas', value: 'las personas'},
        {label: 'la audiencia', value: 'la audiencia'},  

    ];

    const options4 =[
        {label: 'actualizarán', value: 'actualizarán'},
        {label: 'analizarán', value: 'analizarán'},
        {label: 'aplicarán', value: 'aplicarán'},
        {label: 'apoyarán', value: 'apoyarán'},
        {label: 'apreciarán', value: 'apreciarán'},   
        {label: 'argumentarán', value: 'argumentarán'},
        {label: 'calcularán', value: 'calcularán'},
        {label: 'calificarán', value: 'calificarán'},
        {label: 'catalogarán', value: 'catalogarán'},  
        
        {label:'categorizarán', value:'categorizarán'},
        {label:'citarán', value:'citarán'},
        {label:'clasificarán', value:'clasificarán'},
        {label:'combinarán', value:'combinarán'},
        {label:'compararán', value:'compararán'},
        {label:'compilarán', value:'compilarán'},
        {label:'compondrán', value:'compondrán'},
        {label:'conluirán', value:'conluirán'},
        {label:'construirán', value:'construirán'},
        
        {label:'convertirán', value:'convertirán'},
        {label:'criticarán', value:'criticarán'},
        {label:'cuestionarán', value:'cuestionarán'},
        {label:'decidirán', value:'decidirán'},
        {label:'deducirán', value:'deducirán'},
        {label:'definirán', value:'definirán'},
        {label:'demostrarán', value:'demostrarán'},
        {label:'describirán', value:'describirán'},
        {label:'descubrirán', value:'descubrirán'},
        {label:'dibujarán', value:'dibujarán'},
        {label:'diferenciarán', value:'diferenciarán'},
        {label:'discriminarán', value:'discriminarán'},
        {label:'discutirán', value:'discutirán'},
        {label:'distinguirán', value:'distinguirán'},
        {label:'ejemplificarán', value:'ejemplificarán'},
        {label:'emparejarán', value:'emparejarán'},
        {label:'emplearán', value:'emplearán'},
        {label:'enumerarán', value:'enumerarán'},
        {label:'esbozarán', value:'esbozarán'},
        {label:'escribirán', value:'escribirán'},
        {label:'estimarán', value:'estimarán'},
        {label:'examinarán', value:'examinarán'},
        {label:'experimentarán', value:'experimentarán'},
        {label:'explicarán', value:'explicarán'},
        {label:'expresarán', value:'expresarán'},
        {label:'generalizarán', value:'generalizarán'},
        {label:'identificarán', value:'identificarán'},
        {label:'ilustrarán', value:'ilustrarán'},
        {label:'indicarán', value:'indicarán'},
        {label:'inscribirán', value:'inscribirán'},
        {label:'interpretarán', value:'interpretarán'},
        {label:'manipularán', value:'manipularán'},
        {label:'modificarán', value:'modificarán'},
        {label:'nombrarán', value:'nombrarán'},
        {label:'operarán', value:'operarán'},
        {label:'ordenarán', value:'ordenarán'},
        {label:'predecirán', value:'predecirán'},
        {label:'prepararán', value:'prepararán'},
        {label:'producirán', value:'producirán'},
        {label:'programarán', value:'programarán'},
        {label:'recordarán', value:'recordarán'},
        {label:'reenunciarñan', value:'reenunciarñan'},
        {label:'relacionarán', value:'relacionarán'},
        {label:'renunciarán', value:'renunciarán'},
        {label:'repetirán', value:'repetirán'},
        {label:'reproducirán', value:'reproducirán'},
        {label:'resolverán', value:'resolverán'},
        {label:'resumirán', value:'resumirán'},
        {label:'revisarán', value:'revisarán'},
        {label:'rotularán', value:'rotularán'},
        {label:'secuenciarán', value:'secuenciarán'},
        {label:'señalarán', value:'señalarán'},
        {label:'solucionarán', value:'solucionarán'},
        {label:'subrayarán', value:'subrayarán'},
        {label:'traducirán', value:'traducirán'},
        {label:'utilizarán', value:'utilizarán'},
      
    ];

    const options5 = [
        {label:'Los asistentes aumentarán su fe en Dios para observar el futuro de bien que les tiene preparado y dejar de vivir en el pasado.', value:'Los asistentes aumentarán su fe en Dios para observar el futuro de bien que les tiene preparado y dejar de vivir en el pasado.'},
        {label:'La congregación analizara su condición actual para mejorar en todas sus áreas', value:'La congregación analizara su condición actual para mejorar en todas sus áreas'},
        {label:'Al estudiar las escrituras se fomentará en los oyentes la cultura de la lectura para construir una mejor forma de vida.', value:'Al estudiar'},
        {label:'Que la congregación viva una vida más cercana a Dios', value:'Que la congregación viva una vida más cercana a Dios'},
        {label:'La iglesia reconocera la importancia de no ser negativos.', value:'La iglesia reconocera la importancia de no ser negativos.'},
        {label:'La Iglesia sabrá elegir el lugar correcto en medio de la adversidad', value:'La Iglesia sabrá elegir el lugar correcto en medio de la adversidad'},
        {label:'Mejorar sus relaciones interpersonales y cumplir el propósito de vida', value:'Mejorar sus relaciones interpersonales y cumplir el propósito de vida'},
        {label: 'Que la Iglesia se comprometa más con Dios', value:'Que la Iglesia se comprometa más con Dios'},
        {label: 'Expresar de manera genuina una verdadera adoración', value:'Expresar de manera genuina una verdadera adoración'},
        {label: 'Que la Iglesia aprenda a perseverar en la búsqueda de las promesas de Dios', value:'Que la Iglesia aprenda a perseverar en la búsqueda de las promesas de Dios'},
        {label: 'Al conocer la perfecta paternidad de Dios, la Iglesia sabrá que es para su bien', value:'Al conocer la perfecta paternidad de Dios, la Iglesia sabrá que es para su bien'},
        {label: 'Que la Iglesia identifique las causas que los llevan al desierto y cómo salir de él', value:'Que la Iglesia identifique las causas que los llevan al desierto y cómo salir de él'},
        {label: 'Que la Iglesia sepa que no hay lugar mejor para encontrar respuestas, que en Dios.', value:'Que la Iglesia sepa que no hay lugar mejor para encontrar respuestas, que en Dios.'},
        {label: 'La congregación a través del conocimiento de Cristo mejorará su vida espiritual', value:'La congregación a través del conocimiento de Cristo mejorará su vida espiritual'},
        {label: 'La iglesia experimentará el crecimiento en Dios.', value:'La iglesia experimentará el crecimiento en Dios.'},
        {label: 'Que la iglesia aprenda amar como Dios nos ama', value:'Que la iglesia aprenda amar como Dios nos ama'},
        
        {label: 'Vivir siempre fortalecidos como dice la biblia para hacer frente a las adversidades', value:'Vivir siempre fortalecidos como dice la biblia para hacer frente a las adversidades'},
        {label: 'Que los creyentes se afirmen mas en la Fe.', value:'Que los creyentes se afirmen mas en la Fe.'},
        {label: 'Los oyentes al pasar un proceso afirmaran su carácter.', value:'Los oyentes al pasar un proceso afirmaran su carácter.'},
        {label: 'La iglesia ejercitará el amor al estilo de Dios', value:'La iglesia ejercitará el amor al estilo de Dios'},
        {label: 'Alcanzar la salvación', value:'Alcanzar la salvación'},
        {label: '', value:'Personalizado…'},
        
        
        
    ];

  return (
    <div>
        <h1 className='text-dark mt-3'>Plan de la Conferencia</h1>
        <Table striped bordered hover size="sm" className='col-11'>
            <tbody>
                <tr>
                    <td><label htmlFor="">Nombre del Conferencista</label></td>
                    <td>
                        <input 
                            type="text" 
                            placeholder='Nombre' 
                            name="nombre" 
                            value={info.nombre || ""}
                            className='col-6 mx-2'
                            onChange={handleOnchange}
                            
                        />
                        <input     
                            type="text" 
                            placeholder='Apellido' 
                            name="apellido"
                            value={info.apellido || ""}
                            className='col-5'
                            onChange={handleOnchange}
                        />   
                    </td>  
                </tr>
                
                <tr>
                    <td>Cargo/Función</td>
                    <td>    
                       <select
                                name="cargo"
                                value={info.cargo || ""}
                                className='col-11 mx-3'
                                onChange={handleOnchange}
                            >
                                <option value="">Cargo</option>
                                {options.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                        </select>                   
                    </td>          
                </tr>

                <tr>
                    <td>Lugar de Procedencia</td>
                    <td >
                        <input 
                            type="text" 
                            placeholder='Calle' 
                            name='calle'
                            value={info.calle || " "}
                            className='mx-1 col-4'
                            onChange={handleOnchange}
                        />
                        <input 
                            type="text" 
                            placeholder='Ciudad' 
                            name='ciudad'
                            value={info.ciudad || " "}
                            className='mr-1 col-4'
                            onChange={handleOnchange}
                        />
                        <input 
                            type="text"
                            placeholder='Estado' 
                            name='estado'
                            value={info.estado || " "}
                            className='mx- col-3'
                            onChange={handleOnchange}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Tema</td>
                    <td>
                        <input 
                            type="text" 
                            name="tema"
                            value={info.tema || " "}
                            className='col-11'
                            onChange={handleOnchange}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Textos</td>
                    <td>
                        <input type="text" className='col-3 mx-4'/>
                        <input type="text" className='col-3 mx-4'/>
                        <input type="text" className='col-3 mx-4'/>
                    </td>
                </tr>

                <tr>
                    <td>Propósito</td>
                    <td>
                        <div className='d-flex'>
                        <p className='col-2 m-0 ms-4'>Al finalizar</p>
                        <select
                                name="la"
                                value={info.la || ""}
                                className='col-2 me-1'
                                onChange={handleOnchange}
                            >
                                <option value=""> </option>
                                {options2.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                        </select> 
                        <select
                                name="los"
                                value={info.los || ""}
                                className='col-2 mx-1'
                                onChange={handleOnchange}
                            >
                                <option value=""> </option>
                                {options3.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                        </select> 
                        <select
                                name="verbos"
                                value={info.verbos || ""}
                                className='col-2 mx-1'
                                onChange={handleOnchange}
                            >
                                <option value=""> </option>
                                {options4.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                        </select>
                        
                        <input 
                            type="text" 
                            name='proposito'
                            className='col-3'
                            value={info.proposito || ""}
                            onChange={handleOnchange}
                        />
                        </div>
                        
                    </td>
                </tr>

                <tr>
                    <td>Beneficio</td>
                    <td>
                        <select
                                name="beneficio"
                                value={info.beneficio || ""}
                                className='col-11 mx-3'
                                onChange={handleOnchange}
                            >
                                <option value="">Beneficio</option>
                                {options5.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                        </select> 
                    
                    </td>
                </tr>

                <tr>
                    <td>Lugar y Dirección</td>
                    <td >
                        <input 
                            type="text" 
                            name='lugar'
                            className='col-11'
                            onChange={handleOnchange}
                        />
                        
                    </td>
                </tr>

                <tr>
                    <td>Horario</td>
                    <td>
                        <input type="time" name="" id="" className='col-11 text-center'/>
                    </td>
                </tr>

                <tr>
                    <td>Fecha</td>
                    <td>
                        <input type="date" className='col-11 text-center'/>
                    </td>
                </tr>

                <tr>
                    <td>Duracion</td>
                    <td>
                        <select name="" id="" className='col-11 text-center'>
                            <option value="">15 min</option>
                            <option value="">30 min</option>
                            <option value="">45 min</option>
                            <option value="">60 min</option>
                        </select>
                    </td>
                </tr>

            </tbody>
        </Table>
        <div>
            <Link><button className='btn btn-primary'></button></Link>
        </div>
    </div>
    
    
  );
}

export default Formulario;