import React, { useState, useEffect } from 'react';


const BibleDropdown = ({ setSelectedData }) => {
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [books, setBooks] =useState('')
  const [selectedBook, setSelectedBook] = useState('')
  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState([""]);
  const [verses, setVerses] = useState([]);
  const [selectedVerse, setSelectedVerse] = useState('');
  
  

  // Replace with your actual API key
  
  const API_KEY = 'dd654ea29f7c42d1fdcfebeff88436eb';

  
  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await fetch(`https://api.scripture.api.bible/v1/bibles?language=spa&include-full-details=true`, {
          headers: {
            'api-key': API_KEY,
          },
        });
        const data = await response.json();
        setVersions(data.data);  // Adjust this based on actual API response structure
        console.log(data)
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    };

    fetchVersions();
  }, []);
  
  useEffect(() => {
    if (selectedVersion) {
      const fetchBooks = async () => {
        try {
          const response = await fetch(`https://api.scripture.api.bible/v1/bibles/${selectedVersion}/books`, {
            headers: {
              'api-key': API_KEY,
            },
          });
          const data = await response.json();
          setBooks(data.data);  // Adjust this based on actual API response structure
        } catch (error) {
          console.error('Error fetching chapters:', error);
        }
      };

      fetchBooks();
    }
  }, [selectedVersion]);
  

  useEffect(() => {
    if (selectedBook) {
      const fetchCapter = async () => {
        try {
          const response = await fetch(`https://api.scripture.api.bible/v1/bibles/${selectedVersion}/books/${selectedBook}/chapters`, {
            headers: {
              'api-key': API_KEY,
            },
          });
          const data = await response.json();
          setChapters(data.data);  // Adjust this based on actual API response structure
        } catch (error) {
          console.error('Error fetching chapters:', error);
        }
      };

      fetchCapter();
    }
  }, [selectedBook, selectedVersion]);

  useEffect(() => {
    if (selectedChapter) {
      const fetchVerses = async () => {
        try {
          const response = await fetch(`https://api.scripture.api.bible/v1/bibles/${selectedVersion}/chapters/${selectedChapter}/verses`, {
            headers: {
              'api-key': API_KEY,
            },
          });
          const data = await response.json();
          setVerses(data.data);  // Adjust this based on actual API response structure
        } catch (error) {
          console.error('Error fetching verses:', error);
        }
      };

      fetchVerses();
    }
  }, [selectedChapter, selectedVersion]);

    

  const handleVersionChange = (e) => {
    setSelectedVersion(e.target.value);
    const versionId = e.target.value;
    setSelectedData((prev) => ({ ...prev, versionId }));
  };

  const handleBookChange = (e) => {
    setSelectedBook(e.target.value);
    const bookId = e.target.value;
    setSelectedData((prev) => ({ ...prev, book: bookId }));
  };

  const handleChapterChange = (e) => {
    setSelectedChapter(e.target.value);
    const chapterId = e.target.value;
    setSelectedData((prev) => ({ ...prev, chapter: chapterId }));
  };

  const handleVerseChange = (e) => {
    const verseId = e.target.value;
    setSelectedVerse(verseId);
    setSelectedData((prev) => ({ ...prev, verse: verseId }));
  };

  

  return (
    <div>
      <label></label>
      <select value={selectedVersion} onChange={handleVersionChange}>
        <option value="">Version</option>
        {versions?.length > 0 && versions.map((version) => (
          <option key={version.id} value={version.id}>{version.name}</option>
        ))}
      </select>

      <label></label>
      <select value={selectedBook} onChange={handleBookChange} className='m-1'>
        <option value="">Libro</option>
        {books?.length > 0 && books.map((book) => (
          <option key={book.id} value={book.id}>{book.name}</option>
        ))}
      </select>
        
      <label></label>
      <select value={selectedChapter} onChange={handleChapterChange} disabled={!selectedVersion} className='m-1'>
        <option value="">Capitulo</option>
        {chapters?.length > 0 && chapters.map((chapter) => (
          <option key={chapter.id} value={chapter.id}>{chapter.reference}</option>
        ))}
      </select>
        
      <label></label>
      <select value={selectedVerse} onChange={handleVerseChange} disabled={!selectedChapter} className='m-1'>
        <option value="">Versiculo</option>
        {verses?.length > 0 && verses.map((verse) => (
          <option key={verse.id} value={verse.id}>{verse.reference}</option>
        ))}
      </select>

      
    
    </div>
  );
};

export default BibleDropdown;
