import React from 'react';
import Table from 'react-bootstrap/Table';





function Pagina4({info, handleOnchange}) {

    
    
  return (

   

    <div className='col-11 mt-5'>
        <Table className='table table-bordered border border-3 border-dark'>
            <tbody>
                <tr>
                    <td className='bg-secondary'>Duracion</td>
                    <td colSpan={2} className='bg-secondary'>Cierre</td>
                    
                </tr>
                <tr>
                    <td className='col-1' rowSpan={14}><h2 className='mt-5'>10 min</h2></td>
                    <td className='bg-light' colSpan={2}>
                            <h4>Refuerza el mensaje central de la conferencia en la audiencia: </h4>                    
                    </td>
                </tr>
                
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>·Verbalizando a manera de resumen/síntesis el contenido de la conferencia con base en las ideas centrales,</h4></td>
                    
                </tr>
                <tr>
                   <td className='bg-light' colSpan={2}>
                        <textarea
                            type="text"  
                            name=''
                            className='col-12'
                            
                            
                        />
                   </td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>Corrobora que la audiencia recibió el mensaje: 
                    Manteniendo contacto visual con la audiencia, y Planteando preguntas reflexivas congruentes con mensaje transmitido</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}>
                        <textarea
                            type="text"  
                            name='desarrolloicf1'
                            className='col-12'
                            
                        />
                    </td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>Realiza el cierre de la conferencia: 
                    Solicitando a la audiencia ponerse de pie para el cierre de la conferencia,</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>Invitándolos a acercarse al escenario de manera voluntaria para participar en la petición colectiva,</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2} ><h4>Utilizando variantes de tono, modulación, ritmo y volumen de voz que permita a la audiencia escucharle durante la conferencia, </h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2} ><h4>Planteando las ideas con orden, de manera secuencial y congruente a la idea central</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2} ><h4>Agradeciendo a la audiencia por su asistencia, y</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>Solicitando una aclamación de todos. </h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4></h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light'colSpan={2} ><h4>INSTALACIONES, MOBILIARIO Y EQUIPO</h4></td>
                    
                </tr>
                <tr>
                    <td className='bg-light' colSpan={2}><h4>•Auditorio/Espacio/aula con capacidad suficiente para impartir la conferencia</h4>
                                                        <h4>•	Pulpito </h4>
                                                        <h4>•	 6 sillas </h4>
                                                        <h4>•	4 participantes como audiencia  </h4>
                                                        <h4>•	Extensión eléctrica </h4>
                                                        <h4>•	Equipo de audio: De acuerdo a las condiciones de interacción del espacio, número de participantes y en apego al plan de sesión </h4>
                                                        <h4> •	Equipo Informático: Laptop/PC escritorio, Cable HDMI / Adaptador HDMI, Proyector, cañón/pantalla, Presentador inalámbrico.</h4></td>
                </tr>
            </tbody>    
        </Table>
        
    </div>
    
  );
}

export default Pagina4;